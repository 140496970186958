import Splide from "@splidejs/splide";

document.addEventListener("DOMContentLoaded", function () {
  const carouselSimple = document.querySelector(".carousel-block-gutenberg-js");

  if (carouselSimple) {
    const splide = new Splide(carouselSimple).mount();
  }

  const carouselLarge = document.querySelector(
    ".carousel-block-gutenberg-large-js"
  );

  if (carouselLarge) {
    const splide = new Splide(carouselLarge, {
      type: "slide",
      perPage: 1,
      perMove: 1,
      gap: "16px",
      focus: "left",
      rewind: true,
      arrows: true,
      pagination: false,
      mediaQuery: "min",
      breakpoints: {
        768: {
          perPage: 3,
          perMove: 3,
        },
        992: {
          gap: "24px",
        },
        1920: {
          gap: "32px",
        },
      },
    });
    splide.mount();
  }

  const references = document.querySelector(".logo-carousel-js");

  if (references) {
    const splide = new Splide(references, {
      type: "loop",
      perPage: 1,
      perMove: 1,
      gap: "16px",
      focus: "left",
      rewind: false,
      arrows: false,
      pagination: false,
      autoplay: true,
      interval: 3000, // Optional: time in ms between each slide transition
      pauseOnHover: false, // Optional: set to true if you want to pause on hover
      resetProgress: false, // Optional: reset progress on user interaction
      mediaQuery: "min",
      breakpoints: {
        768: {
          perPage: 6,
          perMove: 1,
        },
        992: {
          gap: "24px",
        },
        1920: {
          gap: "32px",
        },
      },
    });

    splide.mount();

    splide.mount();
  }
});
